import '../App.css';
import '../pages/assets/plugins/owl.carousel/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../pages/assets/dist/css/bootstrap.min.css';
import '../pages/assets/css/style.css';
import '../pages/assets/css/shortcodes.css';
import '../pages/assets/css/font-awesome.min.css';
import React from 'react';
import Noticias from './noticias';
import Liquidaciones from './liquidaciones';
import HeaderMain from './headerMain';
import CarouselMain from './carouselMain';
import CarouselClients from './carouselClients';
import Empresa from './empresa';
import Servicios from './servicios';
import ModalPopUp from './modalPopUp';
import { withRouter } from 'react-router-dom';

class Home extends React.Component{

  constructor(props){
    super();
    this.state = {
        loading: true,
        noticia: props.noticias,
        liquidacion: props.lquidaciones,
        popup: true,
        isMounted: false
    }
  }

  componentDidMount(){
    this.setState({ isMounted: true}, () => {
      setTimeout(() => {
        this.scrollToHash();
      }, 1100);
    })
    document.getElementById("about").style.display = 'block';
  }

  componentDidUpdate() {
    if (this.props.location.hash && this.state.isMounted) {
      this.scrollToHash();
    }
  }

  scrollToHash() {
    const hash = this.props.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  render(){
    
  return (    
    <div>
      <ModalPopUp />

      <header>
        <HeaderMain home={true}/>
      </header>
      <main role="main">
        <section id="intro" class="intro-section">
          <CarouselMain />
          <div class="container intro-banner">
            <div class="row">
              <div class="col-lg-4 intro-panel">
                <Noticias />
              </div>
              <div class="col-lg-4 intro-panel">
                <Liquidaciones />
              </div>
              <div class="col-lg-4 intro-panel">
                <Servicios />
              </div>
            </div>
          </div>
        </section>
        
        <Empresa />

        <section id="servicios" class="page-content">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="main-heading">
                  <h4>20 AÑOS EN EL MERCADO</h4>
                  <h1>Nuestros Servicios</h1>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="service-box">
                  <div class="icons">
                    <div class="service-icon">
                      <i class="fa fa-medkit"></i>
                    </div>
                    <h3>SERVICIOS A<br/>OBRAS SOCIALES</h3>
                  </div>
                  <ul>
                    <li>Red de farmacias (definida por la entidad de salud)</li>
                    <li>Auditoría de recetas de medicamentos ambulatorios</li>
                    <li>Gerenciamiento clínico y reportes de consumo de medicamentos</li>
                    <li>Validación on line de recetas</li>
                    <li>Liquidación de dispensación de medicamentos a pedido de la entidad de salud.</li>
                    <li>Reportes especiales</li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="service-box">
                  <div class="icons">
                    <div class="service-icon">
                      <i class="fa fa-medkit"></i>
                    </div>
                    <h3>SERVICIOS <br/>A FARMACIAS</h3>
                  </div>
                  <ul>
                    <li>Disponibilidad de normas de dispensación, padrones (definida por la entidad de salud)</li>
                    <li>Archivos de productos cubiertos por entidad</li>
                    <li>Noticias y novedades</li>
                    <li>Liquidaciones e información de pagos</li>
                    <li>Call center</li>
                    <li>Informes</li>
                    <li>Validación on line de recetas</li>
                    <li>Aplicación FLK-Móvil</li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="service-box">
                  <div class="icons">
                    <div class="service-icon">
                      <i class="fa fa-medkit"></i>
                    </div>
                    <h3>SERVICIOS <br/>A DROGUERÍAS</h3>
                  </div>
                  <ul>
                    <li>Liquidaciones</li>
                    <li>Informes</li>
                    <li>Noticias y novedades</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="clientes" class="page-content green">
          <div class="container">
            <div class="row">
            <div class="col-sm-12">
              <div class="main-heading">
              <h4>20 años en el mercado</h4>
              <h1>Nuestros clientes</h1>
              </div>
            </div>
            <CarouselClients />
            <div class="col-sm-12">
              <a href="clientesLista.html">
              <p class="h4 text-center mt-3">TODOS NUESTROS CLIENTES</p>
              </a>
            </div>
            </div>
          </div>
	      </section>

        <section id="contacto" class="page-content last">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="main-heading wow fadeInDown" data-wow-duration="1s" data-wow-delay="1s">
                  <h4> </h4>
                  <h1>Contacto</h1>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="info-box">
                  <div class="info-icon">
                    <i class="fa fa-map-marker"></i>
                  </div>
                  <div class="info-text">
                    <h3>Dirección</h3>
                    <p>Paraguay 1178 - P.B. (C1057AAR) - Ciudad Autónoma de Buenos Aires - Argentina</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="info-box">
                  <div class="info-icon">
                    <i class="fa fa-mobile"></i>
                  </div>
                  <div class="info-text">
                    <h3>Teléfono</h3>
                    <p>(54-11) 5984-7900</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="info-box">
                  <div class="info-icon">
                    <i class="fa fa-envelope-o" style={{fontSize:'24px'}}></i>
                  </div>
                  <div class="info-text">
                    <h3>Email</h3>
                    <a href="mailto:portal@farmalink.com.ar">portal@farmalink.com.ar</a>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </section>

        
      </main>
    </div>
  );
}}

export default withRouter(Home);

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

const style = {
  phoneSpan1: {
    width: '500px',
    height: 'max-content',
    margin: '20px 0 14px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.44',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#39454e'
  },
  textStyle1: {
    marginLeft: '5px',
    marginRight: '5px',
    width: '80%',
    fontWeight: 'bold',
    color: '#262c30'
  },
  textStyle2: {
    fontWeight: 'bold'
  },
  closeButtonWrap: {
    position: 'relative',
    width: 'max-content',
    left: '95%'
  },
  closeButtonBtn: {
    color: 'white',
    position: 'absolute',
    top: 13.2,
    right: -5
  },
  closeButtonBtnBlack: {
    color: 'black',
    position: 'absolute',
    top: 13.2,
    right: -5
  },
  headerStyle:{
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'relative'
  },
  headerText:{
    position: 'initial',
    top: '10px',
    left: '9%',
    textShadow: '0 3px 6px rgba(0, 0, 0, 0.46)',
    fontFamily: 'Roboto',
    fontSize: '29px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'black'
  }
}