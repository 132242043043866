import React from 'react';
/*import '../pages/assets/plugins/owl.carousel/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';*/
import backgroundImg from '../pages/assets/img/introLanding.jpg'
import createHistory from 'history/createBrowserHistory';
import HeaderMain from './headerMain';
import { HashRouter as Router } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default class PagLiquidaciones extends React.Component{
    constructor(props) {
        super();
        let tamaño = props.liquidacion && props.liquidacion.length <= 20 ? props.liquidacion.length : 20;
        let array = [];
        if(props.liquidacion){
            for(let i = 0; i<tamaño;i++){
                array.push(props.liquidacion[i]);
            }
        }
        this.state = {
          return: array
        }
    }

    componentDidMount(){
        const history = createHistory();
        const previousHash = history.location.hash;
        document.getElementById("map-farma").style.display = 'none';
        document.getElementById("about").style.display = 'block';
        /*if(!this.state.return[0]){
            const urlLiquidaciones = getLiquidaciones()+'/getLiquidaciones';
            var respuesta = [];
            fetch(urlLiquidaciones, {
                  method: 'GET',
                  headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                  }
            }).then(response => 
                response.json().then(data => {
                    if(data && data.estado && data.estado.codigo === "0" && data.liquidaciones[0]){
                        let tamanio = data.liquidaciones.length > 20 ? 20 : data.liquidaciones.length;
                        for(var i = 0;i<tamanio;i++){
                            respuesta.push(data.liquidaciones[i]); 
                        }
                    } 
                    this.setState({return: respuesta});
                })
            )
          }*/
        this.backListener = history.listen((location,action) => {
          if(action === 'POP' && location.hash !== previousHash){
            window.location.reload();
          }
        })
    }

    /*shouldComponentUpdate(){
        if(!this.state.return[0]){
            const urlLiquidaciones = getLiquidaciones()+'/getLiquidaciones';
            var respuesta = [];
            fetch(urlLiquidaciones, {
                  method: 'GET',
                  headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                  }
            }).then(response => 
                response.json().then(data => {
                    if(data && data.estado && data.estado.codigo === "0" && data.liquidaciones[0]){
                        let tamanio = data.liquidaciones.length > 20 ? 20 : data.liquidaciones.length;
                        for(var i = 0;i<tamanio;i++){
                            respuesta.push(data.liquidaciones[i]); 
                        }
                    } 
                    this.setState({return: respuesta});
                })
            )
        }
    }*/

    componentWillUnmount(){
        document.getElementById("map-farma").style.display = 'inline-block';
        this.backListener();
        
    }
    
    /*componentDidMount(){
        const urlLiquidaciones = getLiquidaciones()+'/getLiquidaciones';
        var respuesta = [];
        fetch(urlLiquidaciones, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => 
          response.json().then(data => {
              if(data && data.estado && data.estado.codigo === "0"){
                  let tamanio = data.liquidaciones.length > 20 ? 20 : data.liquidaciones.length;
                  for(var i = 0;i<tamanio;i++){
                      respuesta.push(data.liquidaciones[i]); 
                  }
              } 
              this.setState({return: respuesta});
          })
      )
    }*/
    
    render(){

        function goToTopFunc(){
            document.querySelector('body').scrollTo(0,0);
        }

        return( 
        <div>
            <header>
                <HeaderMain home={false}/>
            </header>
            <main role="main">
                <section id="faq" class="intro-section-subpage">
                    <div class="subpageFarma" style={{backgroundImage: `url(${backgroundImg})`}}></div>
                    <div class="intro-subpage">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="main-heading">
                                        <h4>NOVEDADES</h4>
                                        <h1>Últimas Liquidaciones</h1>
                                    </div>
                                </div>

                                <div class="col-12 content">
                                    <div class="row justify-content-md-center">
                                        <div class="col col-lg-10">
                                            {this.state.return && this.state.return[0] ? this.state.return.map((el) => (
                                                <a class="list-public">
                                                    <div class="row">
                                                        <div class="col-sm-3 date">{el.periodo}</div>
                                                        <div class="col-sm-6 title">{el.titulo}</div>
                                                        <div class="col-sm-3 hidden-xs">
                                                            <img src={el.urlImagenContent} class="img-responsive" alt=""/>
                                                        </div>
                                                    </div>
                                                </a>
                                            )) : <p style={{textAlign: 'center'}}>No es posible recuperar la lista de liquidaciones</p>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                </section>

                
            </main>
        </div>
        );
    }

    
}

/*<div id="back-top">
                    <Router>
                        <HashLink class="rounded" to="/liquidaciones/#top">
                            <i class="fa fa-angle-up"></i>
                        </HashLink>
                    </Router>
                </div>*/