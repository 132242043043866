import '../pages/assets/dist/css/bootstrap.min.css';
import '../pages/assets/css/style.css';
import '../pages/assets/css/shortcodes.css';
import '../pages/assets/css/font-awesome.min.css';
import empresaImg from '../pages/assets/img/empresa.png';

function Empresa(){
    return(
    <section id="empresa" class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="main-heading">
              <h4>Quiénes somos</h4>
              <h1>Nuestra Empresa</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="features parallax-area parallax-image" data-stellar-background-ratio="0.4">
        <div class="page-overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <div class="mackbook">
                <img src={empresaImg} alt=""/>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="subtitulo-heading subtitulo fadeInDown">
              <h1>Quiénes somos</h1>
            </div>
            <h4>Somos una empresa especializada en la administración y auditoría de <b>la dispensación de medicamentos
              ambulatorios en las farmacias</b> para agentes del Seguro de salud (obras sociales, empresas de medicina
              prepaga, etc.) en todo el país.
            </h4>
            <h4>Con presencia desde hace más de 15 años en el área de la salud, hemos consolidado una posición de
              liderazgo en nuestro mercado para el cual auditamos actualmente más de 10 millones de recetas de
              medicamentos ambulatorios por mes, incluyendo a más de 60 agentes del seguro de salud, logrando vínculos
              comerciales sólidos.</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="subtitulo-heading subtitulo fadeInDown">
              <h1>Qué ofrecemos</h1>
            </div>
            <h4>Contamos con un Datacenter con tecnología de avanzada y bajo estándares de seguridad locales e
              internacionales que, sumado a la capacidad de nuestros recursos humanos, permite que nos anticipemos a los
              cambios del negocio, facilitando soluciones eficaces que satisfagan las necesidades de nuestros clientes.
            </h4>
            <h4>Para la auditoría de las recetas, eje de la actividad de Farmalink, generamos y ponemos a disposición de
              nuestros clientes la posibilidad de utilizar herramientas de gestión innovadoras para la administración,
              control, integración de la cadena de valor y soporte a la toma de decisiones a través de políticas de
              calidad y ética profesional en nuestra gestión.</h4>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="subtitulo-heading subtitulo fadeInDown">
              <h1>Qué hacemos</h1>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="features-box">
              <div class="icon-box rounded-circle">
                <i class="fa fa-check"></i>
              </div>
              <div class="features-text">
                <h4>Reportes online</h4>
                <p>Ofrecemos sistemas de análisis y reportes de información consolidada y en línea para la toma de
                  decisiones por parte de los agentes de salud. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="features-box">
              <div class="icon-box rounded-circle">
                <i class="fa fa-check"></i>
              </div>
              <div class="features-text">
                <h4>Programas de gerenciamiento clínico por patología.</h4>
                <p>Ejecutamos los programas de gerenciamiento clínico por patologías definidos por los agentes de salud.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="features-box">
              <div class="icon-box rounded-circle">
                <i class="fa fa-check"></i>
              </div>
              <div class="features-text">
                <h4>Auditoría farmacéutica de recetas de medicamentos ambulatorios.</h4>
                <p>Disponemos de un Datacenter propio, con tecnología de avanzada y bajo estándares de seguridad locales
                  e internacionales.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="features-box">
              <div class="icon-box rounded-circle">
                <i class="fa fa-check"></i>
              </div>
              <div class="features-text">
                <h4>Sistemas para control de consumos on-line</h4>
                <p>Ponemos a disposición de nuestros clientes sistemas para el control de consumos de medicamentos
                  on-line basado en evaluación de reglas y patrones. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="features-box">
              <div class="icon-box rounded-circle">
                <i class="fa fa-check"></i>
              </div>
              <div class="features-text">
                <h4>Liquidación de prestaciones</h4>
                <p>Entregamos liquidaciones de dispensaciones de medicamentos ambulatorios a los distintos actores y
                  financiadores del mercado farmacéutico (agentes del seguro de salud, laboratorios, droguerías y
                  farmacias) siguiendo las indicaciones que nuestros clientes nos instruyan. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="features-box">
              <div class="icon-box rounded-circle">
                <i class="fa fa-check"></i>
              </div>
              <div class="features-text">
                <h4>Validación online de recetas</h4>
                <p>Validación online de recetas de medicamentos ambulatorios en las farmacias de la red que el agente de
                  salud establezca. Contamos con la mayor red del país con más de 12 mil farmacias utilizando este
                  servicio por los distintos agentes de salud.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
}

export default Empresa;