import '../pages/assets/dist/css/bootstrap.min.css';
import '../pages/assets/css/style.css';
import '../pages/assets/css/shortcodes.css';
import '../pages/assets/css/font-awesome.min.css';
import userPlus from '../pages/assets/img/user-plus-solid.svg';
import { HashRouter as Router } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Servicios(){
    return(
        <div class="intro-servicios">
            <i class="fa fa-user fa-6"></i>
            <h2> SERVICIOS DISPONIBLES </h2>
            <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                  <a class="nav-link" href="/webcenter/portal/FarmalinkPortal">
                    <i class="fa fa-unlock-alt pr-2" aria-hidden="true"></i>INGRESAR AL SISTEMA</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " href="/webcenter/portal/autoservicio">
                    <img src={userPlus} sizes="24px" alt="userPlus"/> REGISTRARSE</a> 
                </li>
                <li class="nav-item">
                  <a class="nav-link " href="http://www.imed.com.ar/" target="_blank" rel="noreferrer">
                    <i class="fa fa-check-square-o pr-2" aria-hidden="true"></i>VALIDACIÓN DE RECETAS ONLINE</a>
                </li>
                <li class="nav-item">
                  <Router forceRefresh={false}>
                    <HashLink class="nav-link page-scroll" to="/#contacto">
                      <i class="fa fa-question-circle pr-2" aria-hidden="true"></i>CONTACTO
                    </HashLink>
                  </Router>
                </li>
            </ul>
        </div>
    );
}

export default Servicios;