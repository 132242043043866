import 'react-app-polyfill/ie11';
import 'core-js/es/string';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import BotonIngreso from './components/botonIngreso';
import App from './App';
import LogoFlk from './components/logo';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
  
if(document.getElementById('botonIngreso')){
  ReactDOM.render(
    <React.StrictMode>
      <BotonIngreso />
    </React.StrictMode>,
    document.getElementById('botonIngreso')
  );
}

if(document.getElementById('logo-Flk')){
  ReactDOM.render(
    <React.StrictMode>
      <LogoFlk />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();