import './App.css';
/*import './pages/assets/plugins/owl.carousel/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';*/
import './pages/assets/dist/css/bootstrap.min.css';
import './pages/assets/css/style.css';
import './pages/assets/css/shortcodes.css';
import './pages/assets/css/font-awesome.min.css';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import { getLiquidaciones, getContent, getApiUrl } from "./config/envConfig";
import PagLiquidaciones from './components/pagLiquidaciones';
import PagNoticias from './components/pagNoticias';
import Home from './components/home';


export default class App extends React.Component{

  constructor(){
    super();
    this.state = {
      noticia: null,
      liquidacion: null,
      loadingLiq: true,
      loadingNot: true,
      popup: true
    }

    this.styleLink = document.createElement('link');
    this.styleLink.rel = 'stylesheet';
    this.styleLink.type = 'text/css';

    this.styleLink.href = getApiUrl + '/cs/groups/public/documents/document/b2qt/mzm3/~edisp/wcc_prod-337657.css';
  }
  
  componentDidMount() {
    document.head.appendChild(this.styleLink);
  }

  componentWillUnmount() {
    document.head.removeChild(this.styleLink);
  }

  async componentDidMount(){
    document.getElementById("about").style.display = 'none';
    const urlLiquidaciones = getLiquidaciones()+'/getLiquidaciones';
    const urlContent = getContent()+'/getNoticiasContent';
    await Promise.all([
      fetch(urlLiquidaciones, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      fetch(urlContent, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contentUser:"weblogic",
          contentUrl:"idc://omni-srv:4444",
          contentPass:"PSuTXaMsUmGaFPAb5qk5vQ=="
        })
      })
    ]).then(([liquidaciones, noticias]) => {
      let liq;
      let not;

      liquidaciones.json().then(data1 => {
        if(data1 && data1.estado && data1.estado.codigo === "0"){
          liq = data1.liquidaciones;
          this.setState({
            liquidacion: liq,
            loadingLiq: false
          });
        }else {
          console.log("sin datos de liquidaciones")
          this.setState({
            loadingLiq:false
          })
        }
      }).catch((err) => {
        console.log("error de liquidaciones", err)
        this.setState({
          loadingLiq:false
        })
      })
      
      noticias.json().then(data2 => {
        if(data2 && data2.estado && data2.estado.codigo === "0"){
          not = data2.contentList;
          this.setState({
            noticia: not,
            loadingNot: false
          });
        } else {
          console.log("sin datos en noticias")
          this.setState({
            loadingNot:false
          })  
        }
      }).catch((err) => {
        console.log("error de noticias", err)
        this.setState({
          loadingNot:false
        })
      })

    }).catch((err) => {
      console.log(err)
      this.setState({
        loadingLiq:false,
        loadingNot:false
      })
    })
  }

  render(){
    return(
      <Router>
        {this.state.loadingLiq || this.state.loadingNot ? <div id="preloader">
          <div id="logo"/>
          <div id="loading"/>
        </div>: null
        }
        <div>
            <Route exact path="/"><Home /></Route>
            <Route path="/home/#/"><Home /></Route>
            <Route path="/liquidaciones"> <PagLiquidaciones key={this.state.liquidacion} liquidacion={this.state.liquidacion}/></Route>
            <Route path="/noticias"><PagNoticias key={this.state.noticia} noticia={this.state.noticia}/></Route>
        </div>
        <div id="back-top">
            <a class="rounded" href="#top">
                <i class="fa fa-angle-up"></i>
            </a>
        </div>
      </Router>
      
    )
  }
}

/*basename={process.env.PUBLIC_URL}*/