export const getApiUrl = () => {
    let apiUrl = process.env.REACT_APP_URL;
    return apiUrl;
}

export const getLiquidaciones = () => {
    let apiUrl = process.env.REACT_APP_URL;
    let liquidaciones;

    if (process.env.NODE_ENV === 'development' && apiUrl){
        liquidaciones = apiUrl + process.env.REACT_APP_LIQUIDACIONES_PORT;
    } else {
        liquidaciones = `${apiUrl}/api/msLiquidaciones`
    }

    return liquidaciones;
}

export const getContent = () => {
    let apiUrl = process.env.REACT_APP_URL;
    let content;

    if (process.env.NODE_ENV === 'development' && apiUrl){
        content = apiUrl + process.env.REACT_APP_CONTENT_PORT;
    } else {
        content = `${apiUrl}/api/msContent`
    }

    return content;
}

export const getParametros = () => {
    let apiUrl = process.env.REACT_APP_URL;
    let parametros;

    if (process.env.NODE_ENV === 'development' && apiUrl){
        parametros = apiUrl + process.env.REACT_APP_PARAMETROS_PORT;
    } else {
        parametros = `${apiUrl}/api/msParametros`
    }

    return parametros;
}
