
//import '../pages/assets/plugins/owl.carousel/owl.carousel.css';
import OmintLogo from '../pages/assets/img/clientes/OMINT.jpg';
import AprossLogo from '../pages/assets/img/clientes/APROSS.jpg';
import GalenoLogo from '../pages/assets/img/clientes/GALENO.jpg';
import IomaLogo from '../pages/assets/img/clientes/IOMA.jpg';
import MedicusLogo from '../pages/assets/img/clientes/MEDICUS.jpg';
import MedifeLogo from '../pages/assets/img/clientes/MEDIFE.jpg';
import OsdeLogo from '../pages/assets/img/clientes/OSDE.jpg';
import OsepLogo from '../pages/assets/img/clientes/OSEP.jpg';
import SwissMedicalLogo from '../pages/assets/img/clientes/SWISS_MEDICAL.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css'
import '../pages/assets/dist/css/bootstrap.min.css'

function CarouselClients(){
    let responsiveOwl = {
        responsive: {
            320: {
                items: 1,
                nav: true
            },
            480: {
                items: 2,
                nav: true
            },
            600: {
                items: 3,
                nav: true
            },
            1000: {
                items: 4,
                nav: true,
                loop: true,
            }
        }
    }
    return(
        <OwlCarousel className='owl-height' autoWidth={false} items={4} margin={15} loop={true} nav={true} slideBy={1} dots={false} center={false} autoplay={true} navText={['', '']} responsive={responsiveOwl}>
            <div>
				<div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="http://www.omint.com.ar/" target="_blank" rel="noreferrer" title="OMINT"><img
						src={OmintLogo} alt="OMINT"/></a>
				</div>
			</div>
			<div>
            <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="https://www.osde.com.ar/" target="_blank" rel="noreferrer" title="OSDE"><img
						src={OsdeLogo} alt="OSDE"/></a>
				</div>
			</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="http://osepmendoza.com.ar/" target="_blank" rel="noreferrer" title="OSEP"><img
						src={OsepLogo} alt="OSEP"/></a>
				  </div>
				</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="https://www.swissmedical.com.ar/" target="_blank" rel="noreferrer" title="SWISS MEDICAL GROUP"><img
						src={SwissMedicalLogo} alt="SWISS MEDICAL GROUP"/></a>
				  </div>
				</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="http://www.apross.gov.ar/" target="_blank" rel="noreferrer" title="APROSS"><img
						src={AprossLogo} alt="APROSS"/></a>
				  </div>
				</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="https://www.e-galeno.com.ar/Pages/default.aspx" target="_blank" rel="noreferrer" title="GALENO"><img
						src={GalenoLogo} alt="GALENO"/></a>
				  </div>
				</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="http://www.ioma.gba.gov.ar/" target="_blank" rel="noreferrer" title="IOMA"><img
						src={IomaLogo} alt="IOMA"/></a>
				  </div>
				</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="https://www.medicus.com.ar/" target="_blank" rel="noreferrer" title="MEDICUS"><img
						src={MedicusLogo} alt="MEDICUS"/></a>
				  </div>
				</div>
				<div>
                <div class="client-box" style={{width:"273.75px", height:"196.83px"}}>
					<a href="https://www.medife.com.ar/" target="_blank" rel="noreferrer" title="MEDIFE"><img
						src={MedifeLogo} alt="MEDIFE"/></a>
				  </div>
				</div>
        </OwlCarousel>
    );
}

export default CarouselClients;