import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { CloseButton } from 'react-bootstrap';
import { getParametros, getAlertas } from '../config/envConfig';


export default class modalPopUp extends React.Component{
  

  componentDidMount(){
    
    const getParameter = getParametros() + '/getParameter/';
    const getTitulo = "PARAM_TITULO_HOME";
    const getUrl  = "PARAM_URL_HOME";
    const getCuerpo = "PARAM_CUERPO_HOME";
    
    fetch(getParameter+getTitulo, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => 
      response.json().then(data => {
        this.setState({tituloHome: data.valor});
      })
    ).catch(err => {
      console.log(err)
    })

    fetch(getParameter+getUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => 
      response.json().then(data => {
        
        this.setState({urlHome: data.valor});
      })
    ).catch(err => {
      console.log(err)
    })

    fetch(getParameter+getCuerpo, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => 
      response.json().then(data => {
        
        this.setState({cuerpoHome: data.valor});
      })
    ).catch(err => {
      console.log(err)
    })   

    const getPopUp = "PARAM_POPUP_HOME";
    fetch(getParameter+getPopUp, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => 
      response.json().then(data => {
        this.setState({viewPopUp: data.valor});
      })
    ).catch(err => {
      console.log(err)
    })
  }

    constructor(props){
      super();
      this.state = {
          popup: true
      }
    }
  
    render(){
      let cuerpoItems = [];
      if(this.state.cuerpoHome && this.state.cuerpoHome !== undefined){
        let tex = this.state.cuerpoHome;
        cuerpoItems = tex.split("<br>");
        for (let i = 0; i < cuerpoItems.length; i++) {
          cuerpoItems[i] = cuerpoItems[i] + "<br>";
        }
        cuerpoItems = cuerpoItems.join("");
        document.getElementById("ovde").innerHTML = cuerpoItems;
      }

      if(this.state.viewPopUp !== undefined && this.state.viewPopUp == 'true'){
      return (
        <div>
          <Modal show={true} onHide={()=>{this.setState({ viewPopUp:false });}}  className='mdlnew'>
            <div>
              <div style={style.closeButtonWrap}>
              <CloseButton onClick={() => {
                this.setState({
                  viewPopUp: false
                });
              }} style={style.closeButtonBtnBlack}></CloseButton>
              </div>
    
              <br/>
             
              <h1 class="COMUNICADO-IMPORTANTE">COMUNICADO IMPORTANTE</h1>
    
              <div className='info-modal'>
                
              
              <p>
                <a class="link-nota" href={this.state.urlHome} target="_blank" rel="noreferrer" id="link">Ver Nota completa publicada en el Portal referente a este tema</a>
                </p>
                
                <p>
                <span style={{fontStyle:'italic'}}>{this.state.tituloHome}</span><br/>
                Estimados:
                </p>
                <p id="ovde" >
                  {cuerpoItems}
                </p>
              </div>
    
    
            </div>
          </Modal>
        </div>
      );
    }else{
      return (
        <div>
            <p id="ovde" >
                </p>
        </div>
      );
    }
  }}

  const style = {
    closeButtonWrap: {
      position: 'relative',
      width: 'max-content',
      left: '95%'
    },
    closeButtonBtn: {
      color: 'white',
      position: 'absolute',
      top: 13.2,
      right: -5
    },
    closeButtonBtnBlack: {
      color: 'black',
      position: 'absolute',
      top: 13.2,
      right: -5
    }
  }