import '../pages/assets/dist/css/bootstrap.min.css'
import BotonIngreso from './botonIngreso';
import { HashRouter as Router, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function HeaderMain(props){
    var logoLoc = getLogo();
    if(props.home){
        return(
            <nav class="navbar navbar-expand-md fixed-top navbar-light bg-light">
              <div class="container">
                <a class="navbar-brand" href="/home/#/">
                  <div dangerouslySetInnerHTML={{__html:logoLoc}}></div>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul id="appBarHome" class="nav navbar-nav mr-auto">
                    <Router forceRefresh={true}>
                    <li class="nav-item">
                      <HashLink class="nav-link" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#empresa">LA EMPRESA</HashLink>
                    </li>
                    <li class="nav-item">
                      <HashLink class="nav-link" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#servicios">SERVICIOS</HashLink>
                    </li>
                    <li class="nav-item">
                      <HashLink class="nav-link" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#clientes">CLIENTES</HashLink>
                    </li>
                  <li class="nav-item">
                    <a id="codigo-etica-link" class="nav-link" href="/home/codigoEtica.html">CÓDIGO ÉTICA</a>
                  </li></Router>
                  <Router forceRefresh={false}>
                    <li class="nav-item">
                      <Link class="nav-link" to="/noticias">NOTICIAS</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/liquidaciones">LIQUIDACIONES</Link>
                    </li>
                  </Router>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        AYUDA
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="/home/preguntasFrecuentes.html">Preguntas Frecuentes</a>
                        <a class="dropdown-item" href="/home/linkInteres.html">Links de Interés</a>
                        <Router forceRefresh={false}>
                          <HashLink class="dropdown-item" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#contacto">Contacto</HashLink>
                        </Router>
                    </div>
                  </li>
                </ul>
                  <BotonIngreso />
                </div>
            </div>
            </nav>
        );
    } else {
        return(
            <nav class="navbar navbar-expand-md fixed-top navbar-light bg-light">
              <div class="container">
                <a class="navbar-brand" href="/home/#/">
                    <div dangerouslySetInnerHTML={{__html:logoLoc}}></div>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul id="appBarHome" class="nav navbar-nav mr-auto">
                    <Router forceRefresh={true}>
                    <li class="nav-item">
                      <HashLink class="nav-link" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#empresa">LA EMPRESA</HashLink>
                    </li>
                    <li class="nav-item">
                      <HashLink class="nav-link" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#servicios">SERVICIOS</HashLink>
                    </li>
                    <li class="nav-item">
                      <HashLink class="nav-link" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#clientes">CLIENTES</HashLink>
                    </li>
                  <li class="nav-item">
                    <a id="codigo-etica-link" class="nav-link" href="/home/codigoEtica.html">CÓDIGO ÉTICA</a>
                  </li></Router>
                  <Router>
                  <li class="nav-item">
                      <Link class="nav-link" to="/noticias">NOTICIAS</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/liquidaciones">LIQUIDACIONES</Link>
                    </li>
                  </Router>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        AYUDA
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="/home/preguntasFrecuentes.html">Preguntas Frecuentes</a>
                        <a class="dropdown-item" href="/home/linkInteres.html">Links de Interés</a>
                        <Router forceRefresh={false}>
                          <HashLink class="dropdown-item" data-scroll data-speed="2000" data-easing="easeOutQuint" data-url="false" to="/#contacto">Contacto</HashLink>
                        </Router>
                    </div>
                  </li>
                </ul>
                  <BotonIngreso />
                </div>
            </div>
            </nav>
        );
    }
}

function getLogo(){
    var currentDay = new Date().getDate();
    var currentMonth = new Date().getMonth();
    var currentDayOfTheWeek = new Date().getDay();
    var memoria = (currentDay === 23 || currentDay === 24 || currentDay === 25) && currentMonth === 2;
    var malvinas = (currentDay === 1 || currentDay === 2 || currentDay === 3) && currentMonth === 3;
    var revolucion = (currentDay === 24 || currentDay === 25 || currentDay === 26) && currentMonth === 4;
    var belgrano = (currentDay === 19 || currentDay === 20 || currentDay === 21) && currentMonth === 5;
    var independencia = (currentDay === 8 || currentDay === 9 || currentDay === 10) && currentMonth === 6;
    var sanMartinDomingo = (currentDay >= 14 && currentDay <= 20) && currentDayOfTheWeek === 0 ;
    var sanMartinLunes = (currentDay >= 15 && currentDay <= 21) && currentDayOfTheWeek === 1 ;
    var sanMartinMartes = (currentDay >= 16 && currentDay <= 22) && currentDayOfTheWeek === 2 ;
    var sanMartin = (sanMartinDomingo || sanMartinLunes || sanMartinMartes) && currentMonth === 7;
    var soberania = (currentDay === 19 || currentDay === 20 || currentDay === 21) && currentMonth === 10;

    let location = 'assets/img';
    if(window.location.pathname.includes('carousel-pages')){
        location = '../assets/img';
    }
    
    let link = "<a class='navbar-brand' href='/home/#/'><img src='" + location + "/logoFarmalink.png' alt='Farmalink'></a>";
    
    /*OTONIO*/
    if (((currentDay >= 21 && currentMonth === 2) || (currentDay <= 21 && currentMonth === 3)) && (!memoria)) {
        link = link.replace("logoFarmalink", "FLK_logo_otonio");
    }
    /*INVIERNO*/
    if (((currentDay >= 21 && currentMonth === 5) || (currentDay <= 21 && currentMonth === 6)) && !belgrano && !independencia) {
        link = link.replace("logoFarmalink", "FLK_logo_invierno");
    }
    /*PRIMAVERA*/
    if ((currentDay >= 21 && currentMonth === 8) || (currentDay <= 21 && currentMonth === 9)) {
        link = link.replace("logoFarmalink", "FLK_logo_primavera");
    }
    /*VERANO*/ 
    if ((currentDay >= 21 && currentMonth === 11) || (currentDay <= 21 && currentMonth === 0)) {
        link = link.replace("logoFarmalink", "FLK_logo_verano");
    }
    /*PATRIOTA*/
    if (memoria || malvinas || revolucion || belgrano || independencia || sanMartin || soberania) {
        link = link.replace("logoFarmalink", "FLK_logo_patriota");
    }
    /*NAVIDAD*/
    if (((currentDay >= 8 && currentMonth === 11) || (currentDay <= 8 && currentMonth === 0)) && !soberania) {
        link = link.replace("logoFarmalink", "FLK_logo_navidad");
    }

    return link;
}

export default HeaderMain;