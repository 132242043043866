import React from 'react';
import { getContent } from '../config/envConfig';
import { HashRouter as Router, Link } from 'react-router-dom';
import newspaperRegular from '../pages/assets/img/newspaper-regular.svg';

export default class Noticias extends React.Component{
  constructor(props) {
    super();
    let tamaño = props.noticia && props.noticia.length <= 5 ? props.noticia.length : 5;
    let array = [];
    if(props.noticia){
      for(let i = 0; i<tamaño;i++){
          array.push(props.noticia[i]);
      }
    }
    this.state = {
      return: array
    }
  }

  componentDidMount(){
    const urlContent = getContent()+'/getNoticiasContent';
    var noticias = [];
    fetch(urlContent, {
      method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contentUser:"weblogic",
          contentUrl:"idc://omni-srv:4444",
          contentPass:"PSuTXaMsUmGaFPAb5qk5vQ=="
        })
      })
    .then(response => 
      response.json().then(data => {
        if(data && data.estado && data.estado.codigo === "0" && data.contentList[0]){
          let tamanio = data.contentList.length > 5 ? 5 : data.contentList.length;
            for(var i = 0;i<tamanio;i++){
              noticias.push(data.contentList[i]); 
            }
            this.setState({return: noticias});
        }
      })
    ).catch(err => {
      console.log(err)
    })
  }

  render(){
    const array= this.state.return;
    return(
    <div class="intro-info">
      <div class="intro-header">
          <img src={newspaperRegular} alt="newspaperRegular"/>
          <h2> NOTICIAS DESTACADAS </h2>
      </div>
      <div class="list-group">
        {array && array[0] ? array.map((el) => (
          <a href={el ? el.absoluteURL:"#"} class="list-group-item list-group-item-action">
            <h5 class="mb-1">{el ? el.inDate:"00/00/00"}</h5>
          <p class="mb-1">{el ? el.title:"docName"}</p>
        </a>
        )) : <p style={{textAlign: 'center'}}>No es posible recuperar la lista de noticias</p>}
      </div>
      {array && array[0] ? <Router forceRefresh={true}><Link class="intro-more" to="/noticias"> VER TODAS LAS NOTICIAS </Link></Router> : ""}
    </div>
  );
  }
}