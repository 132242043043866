import '../pages/assets/dist/css/bootstrap.min.css';
import '../pages/assets/css/style.css';
import '../pages/assets/css/shortcodes.css';
import '../pages/assets/css/font-awesome.min.css';
import React from 'react';
import { HashRouter as Router, Link } from 'react-router-dom';
import calendarCheckRegular from '../pages/assets/img/calendar-check-regular.svg';
import { getLiquidaciones } from '../config/envConfig';

export default class Liquidaciones extends React.Component{
  constructor(props) {
    super();
    let tamaño = props.liquidacion && props.liquidacion.length <= 5 ? props.liquidacion.length : 5;
    let array = [];
    if(props.liquidacion){
      for(let i = 0; i<tamaño;i++){
          array.push(props.liquidacion[i]);
      }
    }
    this.state = {
      return: array
    }
  }

  componentDidMount(){
    const urlLiquidaciones = getLiquidaciones() + '/getLiquidaciones';
    let liquidaciones = [];
    fetch(urlLiquidaciones, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => 
      response.json().then(data => {
        if(data && data.estado && data.estado.codigo === "0" && data.liquidaciones[0]){
          let tamanio = data.liquidaciones.length > 5 ? 5 : data.liquidaciones.length;
          for(let i = 0; i<tamanio; i++){
            liquidaciones.push(data.liquidaciones[i]);
          }
          this.setState({return: liquidaciones});
        }
      })
    ).catch(err => {
      console.log(err)
    })
  }
  
  render(){
    return(
    <div class="intro-info">
      <div class="intro-header">
          <img src={calendarCheckRegular} alt="calendarRegular"/>
          <h2> LIQUIDACIONES DISPONIBLES</h2>
      </div>
      <div class="list-group">
        {this.state.return && this.state.return[0] ? this.state.return.map((el) => (
          <div class="list-group-item">
            <h5 class="mb-1">{el.periodo}</h5>
            <p class="mb-1">{el.titulo}</p>
          </div>
        )) : <p style={{textAlign: 'center'}}>No es posible recuperar la lista de liquidaciones</p>}
      </div>
      {this.state.return && this.state.return[0] ? <Router forceRefresh={true}><Link class="intro-more" to="/liquidaciones"> VER TODAS LAS LIQUIDACIONES </Link></Router> : ""}
    </div>
  );
  }
}