
import '../pages/assets/plugins/owl.carousel/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../pages/assets/dist/css/bootstrap.min.css'

function CarouselMain(){
    return(
        <div id="myCarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div style={{height: '600px'}} class="img-slider receta">
              <div></div>
            </div>
            <div class="container">
              <div class="carousel-caption">
                <h1>Receta Electrónica</h1>
                <p><a class="btn btn-lg btn-primary" href="/home/carousel-pages/recetaElectronica.html">Más Información</a></p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div style={{height: '600px'}} class="img-slider administracion">
              <div></div>
            </div>
            <div class="container">
              <div class="carousel-caption">
                <h1>ADMINISTRACIÓN Y AUDITORÍA</h1>
                <p>DE CONVENIOS DE MEDICAMENTOS AMBULATORIOS PARA OBRAS SOCIALES Y EMPRESAS DE MEDICINA PREPAGA</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div style={{height: '600px'}} class="img-slider auditoria">
              <div></div>
            </div>
            <div class="container">
              <div class="carousel-caption">
                <h1>AUDITORÍA DE DISPENSACIÓN DE MEDICAMENTOS AMBULATORIOS</h1>
                <p>EN MÁS DE 11.000 FARMACIAS EN TIEMPO REAL</p>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    );
}

export default CarouselMain;