

function LogoFlk(){
    let logoLoc = getLogo();
    return(
        <a class="navbar-brand" href="/home/#/">
            <div dangerouslySetInnerHTML={{__html:logoLoc}}></div>
        </a>
    );
}

function getLogo(){
    var currentDay = new Date().getDate();
    var currentMonth = new Date().getMonth();
    var currentDayOfTheWeek = new Date().getDay();
    var memoria = (currentDay === 23 || currentDay === 24 || currentDay === 25) && currentMonth === 2;
    var malvinas = (currentDay === 1 || currentDay === 2 || currentDay === 3) && currentMonth === 3;
    var revolucion = (currentDay === 24 || currentDay === 25 || currentDay === 26) && currentMonth === 4;
    var belgrano = (currentDay === 19 || currentDay === 20 || currentDay === 21) && currentMonth === 5;
    var independencia = (currentDay === 8 || currentDay === 9 || currentDay === 10) && currentMonth === 6;
    var sanMartinDomingo = (currentDay >= 14 && currentDay <= 20) && currentDayOfTheWeek === 0 ;
    var sanMartinLunes = (currentDay >= 15 && currentDay <= 21) && currentDayOfTheWeek === 1 ;
    var sanMartinMartes = (currentDay >= 16 && currentDay <= 22) && currentDayOfTheWeek === 2 ;
    var sanMartin = (sanMartinDomingo || sanMartinLunes || sanMartinMartes) && currentMonth === 7;
    var soberania = (currentDay === 19 || currentDay === 20 || currentDay === 21) && currentMonth === 10;

    let location = 'assets/img';
    if(window.location.pathname.includes('carousel-pages')){
        location = '../assets/img';
    }
    
    let link = "<a class='navbar-brand' href='/home/#/'><img src='" + location + "/logoFarmalink.png' alt='Farmalink'></a>";
    
    /*OTONIO*/
    if (((currentDay >= 21 && currentMonth === 2) || (currentDay <= 21 && currentMonth === 3)) && (!memoria)) {
        link = link.replace("logoFarmalink", "FLK_logo_otonio");
    }
    /*INVIERNO*/
    if (((currentDay >= 21 && currentMonth === 5) || (currentDay <= 21 && currentMonth === 6)) && !belgrano && !independencia) {
        link = link.replace("logoFarmalink", "FLK_logo_invierno");
    }
    /*PRIMAVERA*/
    if ((currentDay >= 21 && currentMonth === 8) || (currentDay <= 21 && currentMonth === 9)) {
        link = link.replace("logoFarmalink", "FLK_logo_primavera");
    }
    /*VERANO*/ 
    if ((currentDay >= 21 && currentMonth === 11) || (currentDay <= 21 && currentMonth === 0)) {
        link = link.replace("logoFarmalink", "FLK_logo_verano");
    }
    /*PATRIOTA*/
    if (memoria || malvinas || revolucion || belgrano || independencia || sanMartin || soberania) {
        link = link.replace("logoFarmalink", "FLK_logo_patriota");
    }
    /*NAVIDAD*/
    if (((currentDay >= 8 && currentMonth === 11) || (currentDay <= 8 && currentMonth === 0)) && !soberania) {
        link = link.replace("logoFarmalink", "FLK_logo_navidad");
    }

    return link;
}

export default LogoFlk;